<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>My Payslips</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">My Payslips</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            
                <b-table
                    show-empty
                    striped
                    hover
                    :items="items"
                    :fields="fields"
                    responsive
                    @row-clicked="rowClick"
                    tbody-tr-class="pointer-cursor"
                >
                    <template v-slot:cell(employee.full_name)="row">
                        {{row.item.employee.full_name}} [IES-{{row.item.emp_id}}]
                    </template>
                    <template v-slot:cell(month)="row">
                        {{getMonthName(row.item.month)}}
                    </template>
                    <template v-slot:cell(actions)="row">
                        <div>
                            <router-link
                                :to="{name:'my-payslip-details', params: { emp_id: row.item.emp_id, id: row.item.id }}"
                            >   
                                <button class="btn-sm btn rounded-circle btn-primary mr-2" v-b-tooltip.hover title="View Payslip Details"> 
                                    <font-awesome-icon icon="eye" size="1x"></font-awesome-icon>
                                </button>
                            </router-link>
                        </div>
                    </template>
                </b-table>
        </b-container>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            api_error: "",
            fields: [
                {key: "employee.full_name", label:"Employee Name"},
                { key: "month"},
                // { key: "gross_salary"},
                // {key: "net_pay"},
                {key: "actions", label:"Actions"}
            ],
            items: null,
            filter: null,
            access: true,
            years: [],
            total_years:2,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            username:"",
            current_employee_id: null
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_VIEW_MY_PAYSLIPS");
        const data = window.$cookies.get("user");
        this.username = data.user_details.username;
        this.getPayslips();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        getMonthName(number){
            let month_number = parseInt(number) -1
            let mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
            return mlist[month_number];
        },

        getDefaultFilters() {
            let api_params = {
                year: this.year
            };
            return api_params;
        },

        onPageLoad() {
            const api_params =
                Object.keys(this.$route.query).length > 0
                    ? this.$route.query
                    : this.getDefaultFilters();
            this.year = api_params.year;
            this.getPayroll(api_params);
        },

        dateformat (date) {
            var dateFormat = new Date(date);
            var dd = String(dateFormat.getDate()).padStart(2, '0');
            var mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
            var yyyy = dateFormat.getFullYear();
            return dd + '-' + mm + '-' + yyyy;
        },

        getPayslips() {
            this.items = [];
            this.startProgressBar();
            var  query = this.getAuthHeaders();
            query["params"] = this.deleteEmptyKeys();
            this.axios.get(this.$api.get_payroll, query).then(response => {
                this.items = response.data.data;
                this.updateProgressBar(true);
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.items = [];
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        onSelection() {
            this.getPayslips(this.getDefaultFilters());
        },

        rowClick(item){
            this.$router.push({name:'my-payslip-details', params: {emp_id:item.emp_id, id: item.id}});
        }
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
.pointer-cursor {
    cursor:pointer !important;
}

</style>